window.loadPage = function(page) {
    return fetch(`./assets/pages/${page}.html`)
        .then(response => response.text())
        .then(data => {
            document.getElementById('mainContent').innerHTML = data;
            history.pushState({ page: page }, '', page === 'home' ? '/' : `/${page}`);
            window.dispatchEvent(new CustomEvent('page-changed', { detail: page }));
        });
};

window.initComponents = function() {
    fetch('./assets/components/header.html')
        .then(response => response.text())
        .then(data => document.getElementById('headerComponent').innerHTML = data);

    fetch('./assets/components/footer.html')
        .then(response => response.text())
        .then(data => document.getElementById('footerComponent').innerHTML = data);
};

window.handleNavigation = function() {
    const path = window.location.pathname;
    const page = path === '/' ? 'home' : path.replace('/', '');
    window.loadPage(page);
};

document.addEventListener('DOMContentLoaded', function() {
    initComponents();
    // Check if a redirect was intended
    const intendedPath = sessionStorage.redirect || window.location.pathname;
    delete sessionStorage.redirect;  // Clean up the sessionStorage
    const initialPage = intendedPath === '/' ? 'home' : intendedPath.replace('/', '');
    loadPage(initialPage);

    window.onpopstate = function(event) {
        // Handle browser navigation events
        handleNavigation();
    };
});
